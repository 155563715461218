import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from "../user-context.js";
import './css/Sidenav.css';
import { useNavigate } from "react-router-dom";
import { API } from '../api-service';
import { IoLink, IoNotificationsOutline } from "react-icons/io5";
import { FiMessageSquare } from "react-icons/fi";
import { TiFlowMerge } from "react-icons/ti";
import { MdChecklist } from "react-icons/md";
import { MdContacts } from "react-icons/md";
import { MdOutlineSettings } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoIosMore } from "react-icons/io";
import { FaChevronDown } from "react-icons/fa";
import { useWebSocketContext } from '../pages/Websocket.js';

function Sidenav() {
    const {imageUrl, userName, setImageUrl} = useContext(UserContext);
    const [sideNavUrl, setSideNavUrl] = useState('');
    const [isContactSubMenuOpen, setContactSubMenuOpen] = useState(false);
    const [isUserMenuOpen, setUserMenuOpen] = useState(false);
    const { resetUserData, userToken, profileId } = useContext(UserContext); 
    const { toggleConnection } = useWebSocketContext();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const deviceId = localStorage.getItem('deviceId');
    const [activeIcon, setActiveIcon] = useState('');

    useEffect(() => {
        // Set the active icon based on the current route
        const path = location.pathname;
        if (path.includes('/main/inbox')) {
            setActiveIcon('inbox');
        } else if (path.includes('/main/link')) {
            setActiveIcon('link');
        } else if (path.includes('/main/todolist')) {
            setActiveIcon('todolist');
        } else if (path.includes('/main/notifications')) {
            setActiveIcon('notifications');
        }
    }, [location]);

    useEffect(()=> {
        setSideNavUrl(imageUrl);
    }, [imageUrl]);

    useEffect(() => {
        const storedImageUrl = localStorage.getItem('userImageUrl');
        if (storedImageUrl) {
            setSideNavUrl(storedImageUrl);
        }
    
        if (imageUrl) {
            setSideNavUrl(imageUrl);
            localStorage.setItem('userImageUrl', imageUrl); 
        }
    }, [imageUrl]);
    
    const toggleContactSubMenu = () => {
        setContactSubMenuOpen(!isContactSubMenuOpen);
    };

    const toggleUserMenu = () => {
        setUserMenuOpen(!isUserMenuOpen);
    };

    const setupPushNotification = async (userToken, profileId, isSubscribed) => {
		try {
			const registration = await navigator.serviceWorker.ready;
			const sub = await registration.pushManager.getSubscription();
			if (sub) {
				const response = await API.setPushNotification({
                    'userToken': userToken,
                    'profileId': profileId,
                    'deviceId': localStorage.getItem('deviceId'),
                    'subscription': sub,
                    'isSubscribed': isSubscribed
                });
				return response;
				
			}
		} catch (error) {
			console.error('Error setting push notification:', error);
		}
	};

    const logoutUser = () => {
        setupPushNotification(userToken, profileId, false);
        API.logoutUserFunction(userToken);
        toggleConnection(false);
        resetUserData();
        navigate('/');
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const toggleMobileUserMenu = (event) => {
        event.stopPropagation();
        setUserMenuOpen(!isUserMenuOpen);
    };

    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    return (
        <>
            <button className="menu-toggle" onClick={toggleSidebar}><RxHamburgerMenu /></button>
            <nav className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                <ul className="sidebar-nav">
                    <div className="side-item">
                        <img src={require("../assets/transparent_logo.png")} alt="" width="80"/>
                        <span className="link-text" id="logo-text">STREAMHOST</span>
                    </div>

                    <div className="side-main">
                        <p>MAIN</p>
                    </div>
                    
                    <li className={`nav-item ${isActive('/main/link')}`}>
                        <Link to="/main/link" className="nav-link">
                            <IoLink className="nav-icon"/>
                            <span className="link-text">Whatsapp accounts</span>
                        </Link>
                    </li>
                    
                    <li className={`nav-item ${isActive('/main/inbox')}`}>
                        <Link to="/main/inbox" className="nav-link">
                            <FiMessageSquare className="nav-icon"/>
                            <span className="link-text">Inbox</span>
                        </Link>
                    </li>

                    <li className={`nav-item ${isActive('/main/todolist')}`}>
                        <Link to='/main/todolist' className='nav-link'>
                            <MdChecklist className="nav-icon"/>
                            <span className='link-text'>To-do list</span>
                        </Link>
                    </li>

                    <li className={`nav-item chatflow-item ${isActive('/main/chatflow')}`}>
                        <Link to="/main/chatflow" className="nav-link">
                            <TiFlowMerge className="nav-icon"/>
                            <span className="link-text">Chatflow</span>
                        </Link>
                    </li>

                    <li className={`nav-item dash-item ${isActive('/main/contact')}`}>
                        <Link to="/main/contact" className="nav-link">
                            <MdContacts className="nav-icon"/>
                            <span className="link-text">Contact</span>
                        </Link>
                    </li> 

                    <li className="nav-item">
                        <div className="nav-link" onClick={toggleContactSubMenu}>
                            <IoIosMore className="nav-icon"/>
                            <span className="link-text">More</span>
                            <FaChevronDown className={`icon-second ${isContactSubMenuOpen ? 'active' : ''}`} />
                        </div>
                    </li>
                    <ul className={`sub-menu ${isContactSubMenuOpen ? 'active' : ''}`}>
                        <li className={isActive('/main/broadcast')}>
                            <Link to="/main/broadcast" className="nav-link subnav">
                                <span className="link-text">Broadcast</span>
                            </Link>
                        </li>
                        <li className={isActive('/main/notifications')}>
                            <Link to="/main/notifications" className="nav-link subnav">
                                <span className="link-text">Notifications</span>
                            </Link>
                        </li>

                        <li className={isActive('/main/dashboard')}>
                            <Link to="/main/dashboard" className="nav-link subnav">
                                <span className="link-text">Dashboard</span>
                            </Link>
                        </li>
                        
                    </ul>
                    
                    <div className="side-setting">
                        <p>SETTINGS</p>
                    </div>

                    <li className={`nav-item ${isActive('/main/settings')}`}>
                        <Link to="/main/settings" className="nav-link">
                            <MdOutlineSettings className="nav-icon"/>
                            <span className="link-text">Settings</span>
                        </Link>
                    </li>

                    <div className="side-account">
                        <p>ACCOUNT</p>
                    </div>

                    <li className="nav-item" onClick={toggleUserMenu} style={{ position: 'relative' }}>
                        {isUserMenuOpen && (
                            <div className="user-menu">
                                <ul>
                                    <li className="user-menu-item" onClick={logoutUser}>
                                        <Link to="/login" className="user-menu-link">Log Out</Link>
                                    </li>
                                </ul>
                            </div>
                        )}
                        <li className="nav-account">
                            <img src={sideNavUrl} alt="" width='40rem' id="user-icon"/>
                            <span className="link-text">{userName}</span>
                        </li>
                    </li>
                </ul>
                <div className="mobile-nav">
                    <Link to="/main/link" className={`mobile-nav-link ${activeIcon === 'link' ? 'active' : ''}`}>
                        <IoLink className="nav-icon"/>
                        <span className="mobile-link-text">Accounts</span>
                    </Link>
                    <Link to="/main/inbox" className={`mobile-nav-link ${activeIcon === 'inbox' ? 'active' : ''}`}>
                        <FiMessageSquare className="nav-icon"/>
                        <span className="mobile-link-text">Inbox</span>
                    </Link>
                    <Link to="/main/notifications" className={`mobile-nav-link ${activeIcon === 'notifications' ? 'active' : ''}`}>
                        <IoNotificationsOutline className="nav-icon"/>
                        <span className="mobile-link-text">Notifications</span>
                    </Link>
                    <Link to='/main/todolist' className={`mobile-nav-link ${activeIcon === 'todolist' ? 'active' : ''}`}>
                        <MdChecklist className="nav-icon"/>
                        <span className='mobile-link-text'>To-do</span>
                    </Link>
                    <div className="mobile-prof-link" onClick={toggleMobileUserMenu}>
                        <img src={sideNavUrl} alt="" width='24rem' id="mobile-user-icon"/>
                        <span className="mobile-link-text"></span>
                        {isUserMenuOpen && (
                            <div className="mobile-user-menu">
                                <ul>
                                    <li className={`mobile-user-menu-item ${isActive('/main/settings') ? 'active' : ''}`}>
                                        <Link to="/main/settings" className="mobile-user-menu-link">Settings</Link>
                                    </li>
                                    <li className="mobile-user-menu-item" onClick={logoutUser}>
                                        <Link to="/login" className="mobile-user-menu-link">Log Out</Link>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Sidenav;