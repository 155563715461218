import React, { useEffect, useState, useContext, useRef, useMemo, useCallback } from 'react';
import { UserContext } from "../user-context.js";
import { API } from "../api-service.js";
import './css/ChatRoomList.css';
import UserImage from '../assets/user.png';
import { useWebSocketContext } from '../pages/Websocket.js';
import { toast } from 'react-toastify';

const ContextMenu = ({ x, y, onDelete, onClose }) => {
    const menuRef = useRef(null);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          onClose();
        } 
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [onClose]);
  
    return (
        <div ref={menuRef} style={{ top: y, left: x }} className="popup-menu">
            <button className="popup-menu-button" onClick={onDelete}>
                Delete Chatroom
            </button>
        </div>
    );
  };

const ChatRoomItem = ({ chatRoom, onChatRoomClick, onDeleteChatRoom, isDisabled }) => {
    const { userToken } = useContext(UserContext);
    const [contextMenu, setContextMenu] = useState(null);

    // Format the last message data from chatRoom.last_message
    const lastMessage = useMemo(() => {
        if (!chatRoom.last_message) {
            return { text: '', date: '', time: '', type: '', id: '' };
        }

        const date = new Date(chatRoom.last_message.timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;
        const formattedTime = date.toLocaleString('en-US', {
            hour: '2-digit', 
            minute: '2-digit'
        });

        return {
            text: chatRoom.last_message.text,
            date: formattedDate,
            time: formattedTime,
            type: chatRoom.last_message.media_type || 'text',
            id: chatRoom.last_message.id
        };
    }, [chatRoom.last_message]);

    const getUserName = input => {
        const regex = /.*\d+/;
        const match = input?.match(regex);
        
        if (match) {
            const lastNumberIndex = match[0].length;
            return input.substring(lastNumberIndex).trim();
        }
        return input;
    };

    // Modify the getInitial function to use getUserName
    const getInitial = (name) => {
        if (!name) return '?';
        const actualName = getUserName(name);
        return actualName.charAt(0).toUpperCase();
    };

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu({
            x: event.clientX,
            y: event.clientY,
        });
    };

    const handleDeleteChatRoom = () => {
        onDeleteChatRoom(chatRoom.id, chatRoom.participants);
        setContextMenu(null);
    };

    const getMessageDateDisplay = (messageDate) => {
        const today = new Date();
        const yesterday = new Date(new Date().setDate(today.getDate() - 1));
    
        // Split the date string into day, month, and year
        if (messageDate) {        
            const [day, month, year] = messageDate.split('/');
            // Create a new Date object using the parsed values
            const messageDateTime = new Date(`${year}-${month}-${day}`);
            
        
            // Convert to strings for comparison to ensure timezone is considered
            const todayDay = String(today.getDate()).padStart(2, '0');
            const todayMonth = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const todayYear = today.getFullYear();
            const todayStr =  `${todayDay}/${todayMonth}/${todayYear}`;
    
            // Get yesterday's date
            const yesterdayDay = String(yesterday.getDate()).padStart(2, '0');
            const yesterdayMonth = String(yesterday.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const yesterdayYear = yesterday.getFullYear();
            const yesterdayStr =  `${yesterdayDay}/${yesterdayMonth}/${yesterdayYear}`;
    
            // Extract day, month, and year
            const msgDateTimeDay = String(messageDateTime.getDate()).padStart(2, '0');
            const msgDateTimeMonth = String(messageDateTime.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const msgDateTimeYear = messageDateTime.getFullYear();
    
            // Format the date as dd/mm/yyyy
            const messageDateStr = `${msgDateTimeDay}/${msgDateTimeMonth}/${msgDateTimeYear}`;
    
            if (messageDateStr === todayStr) {
                // Message was sent today, return nothing
                return 'Today';
            } else if (messageDateStr === yesterdayStr) {
                // Message was sent yesterday
                return 'Yesterday';
            } else if (messageDateStr === 'NaN/NaN/NaN') {
                return ' ';
            } else {
                // Message was sent on another day, return formatted date
                return messageDateStr;
            }
        }


    };
    
    const handleClick = (e) => {
        if (isDisabled) {
            e.preventDefault();
            return;
        }
        onChatRoomClick(chatRoom.id, chatRoom);
    };

    return (
        <div 
            className='chatroom-list'
            onClick={handleClick}
            onContextMenu={handleContextMenu}
            style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
        >
            <div className="inbox-item-pic">
                {chatRoom.participants.map((participant, index) => (
                    <div key={index}>
                        <b>{getInitial(participant.name)}</b>
                    </div>
                ))}
            </div>
            
            <div className="inbox-item-name">
            {chatRoom.participants.map((participant, index) => (
                <div key={index}>
                    <div className='inbox-item-name-and-date'>
                        <h3>{participant.name}</h3>
                        <p className='inbox-item-last-message-timestamp'>
                            {getMessageDateDisplay(lastMessage.date)}
                        </p>
                    </div>
                    <div className="inbox-item-last-message">
                        <p className='inbox-item-last-message-text'>
                            {lastMessage.type !== 'text' ? lastMessage.type : lastMessage.text}
                        </p>
                        {lastMessage.time !== "Invalid Date" && (
                            <p className='inbox-item-last-message-timestamp'>{lastMessage.time}</p>
                        )}
                    </div>
                </div>
            ))}
            
            </div>
            {contextMenu && (
                <ContextMenu
                    x={contextMenu.x}
                    y={contextMenu.y}
                    onDelete={handleDeleteChatRoom}
                    onClose={() => setContextMenu(null)}
                />
            )}
            
        </div>
    );
};


function ChatRoomList({ onChatRoomClick, onChatRoomDelete, onChatRoomRerender, searchQuery }) { 
    const socketRef = useRef();
    const chatRoomCache = useRef(new Map());
    const [chatRooms, setChatRooms] = useState([]);
    const { userToken, wabaId } = useContext(UserContext);
    const { toggleConnection } = useWebSocketContext();
    const [isLoading, setIsLoading] = useState(true);
    const BATCH_SIZE = 10; // Adjust based on your needs
    const [isRoomLoading, setIsRoomLoading] = useState(false);


    

    // Memoize sorted and filtered chatrooms
    const sortedChatRooms = useMemo(() => {
        const filtered = chatRooms.filter(chatRoom =>
            chatRoom.participants.some(participant =>
                participant.name.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );

        const sorted = [...filtered].sort((a, b) => {
            const timeA = a.last_message?.timestamp ? new Date(a.last_message.timestamp).getTime() : 0;
            const timeB = b.last_message?.timestamp ? new Date(b.last_message.timestamp).getTime() : 0;
            return timeB - timeA;
        });
        
        return sorted;
    }, [chatRooms, searchQuery]);

    // WebSocket handler for real-time updates
    useEffect(() => {
        socketRef.current = new WebSocket(`wss://streamhost-django-80bc172b3b26.herokuapp.com/ws/chatroomlist/`);
        toggleConnection(false);

        const handleMessage = (event) => {
            const message = JSON.parse(event.data);
            if (!message.chatRoomId || !message.timestamp) return;
            console.log(message)
            setChatRooms(prevRooms => {
                const updatedRooms = prevRooms.map(room => 
                    room.id === message.chatRoomId
                        ? {
                            ...room,
                            last_message: {
                                text: message.message,
                                timestamp: message.timestamp,
                                media_type: message.media_type || 'text',
                                id: message.messageId
                            }
                        }
                        : room
                );
                
                return updatedRooms;
            });
        };

        socketRef.current.onopen = () => console.log("[chatroomlist] Connected");
        socketRef.current.onmessage = handleMessage;

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
                toggleConnection(true);
            }
        };
    }, [userToken, toggleConnection]);

    // Add new helper function to process chatrooms in parallel batches
    const processChatRoomsInBatches = async (chatrooms, profileData) => {
        const batches = [];
        for (let i = 0; i < chatrooms.length; i += BATCH_SIZE) {
            batches.push(chatrooms.slice(i, i + BATCH_SIZE));
        }

        console.log(`🔄 Processing ${batches.length} batches of ${BATCH_SIZE} rooms`);
        
        const processedBatches = await Promise.all(
            batches.map(async (batch) => {
                // Process each batch in parallel
                const processedRooms = await Promise.all(
                    batch.map(async (room) => {
                        // Add any additional room processing here if needed
                        return room;
                    })
                );
                return processedRooms;
            })
        );

        // Flatten the batches back into a single array
        return processedBatches.flat();
    };

    // Update the fetchChatRooms function
    const fetchChatRooms = useCallback(async () => {
        console.time('Total Loading Time');
        
        

        try {
            console.time('Fetching Chatrooms and Profile');
            const [fetchedChatRooms, profileData] = await Promise.all([
                API.getChatRooms(userToken, wabaId),
                API.fetchProfile(userToken)
            ]);
            console.timeEnd('Fetching Chatrooms and Profile');
            console.log(`📊 Fetched ${fetchedChatRooms.length} chatrooms`);
            
            // Filter based on profile
            const eligibleRooms = !profileData['related_user'] || 
                profileData['building'] === 'All' || 
                !profileData['building']
                    ? fetchedChatRooms
                    : fetchedChatRooms.filter(room => 
                        room.participants[0]?.name?.includes(profileData['building'])
                    );

            // Process rooms in parallel batches
            console.time('Processing Chatrooms');
            const processedRooms = await processChatRoomsInBatches(eligibleRooms, profileData);
            console.timeEnd('Processing Chatrooms');

            // Update cache and state with processedRooms instead of filteredRooms
            
            setChatRooms(processedRooms);

        } catch (error) {
            console.error('❌ Error:', error);
            toast.error('Failed to load chatrooms');
        } finally {
            console.timeEnd('Total Loading Time');
            setIsLoading(false);
        }
    }, [userToken, wabaId]);

    // Initial fetch
    useEffect(() => {
        fetchChatRooms();
    }, [fetchChatRooms]);

    // Handle delete
    const handleDeleteChatRoom = useCallback((chatRoomId, participants) => {
        if (!participants?.length) return;

        API.deleteChatRoom(participants[0].id, userToken)
            .then(() => {
                const updatedRooms = chatRooms.filter(room => room.id !== chatRoomId);
                
                setChatRooms(updatedRooms);
                toast.success("Chatroom deleted", {position: 'bottom-center'});
                onChatRoomDelete(chatRoomId);
            })
            .catch(error => {
                toast.error("Failed to delete chatroom");
                console.error('Error:', error);
            });
    }, [chatRooms, userToken, onChatRoomDelete]);

    // Wrap the click handler with loading check
    const handleChatRoomClick = useCallback(async (chatRoomId, chatRoom) => {
        if (isRoomLoading) {
            console.log('🚫 Blocked - Currently loading another chatroom');
            return;
        }

        try {
            setIsRoomLoading(true);
            await onChatRoomClick(chatRoomId, chatRoom);
        } finally {
            // Add a small delay before allowing next click
            setTimeout(() => {
                setIsRoomLoading(false);
            }, 1000); // 1 second delay
        }
    }, [isRoomLoading, onChatRoomClick]);

    return (
        <div className="chatroom-list-container">
            {isLoading ? (
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            ) : (
                sortedChatRooms.map(chatRoom => (
                    <ChatRoomItem 
                        key={chatRoom.id}
                        chatRoom={chatRoom}
                        onChatRoomClick={handleChatRoomClick}
                        onDeleteChatRoom={handleDeleteChatRoom}
                        isDisabled={isRoomLoading}
                    />
                ))
            )}
        </div>
    );
}

export default React.memo(ChatRoomList);