// Draggable and Droppable components

import React, { useContext, useState, useEffect } from 'react';
import { SortableContext, useSortable, verticalListSortingStrategy} from '@dnd-kit/sortable';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { useNavigate } from 'react-router-dom';
import { API } from '../api-service';
import { IoIosArrowBack } from "react-icons/io";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { RiFolderAddLine } from 'react-icons/ri';
import { IoSend } from "react-icons/io5";
import { toast } from 'react-toastify';
import { CSS } from '@dnd-kit/utilities'; // For draggable CSS
import { MdDelete } from "react-icons/md";
import './css/DragAndDrop.css';
import { UserContext } from '../user-context';
import 'react-toastify/dist/ReactToastify.css';

import './css/DragAndDrop.css';

const ColorPicker = ({ isOpen, onClose, onColorSelect }) => {
    const colors = ['#2C2B3C', '#848FA5', '#4A6670', '#5F634F', '#2D4739', '#907F9F', '#FFD6BA', '#B76D68'];
  
    if (!isOpen) return null;
  
    return (
        <div className="color-picker">
            {colors.map((color) => (
                <div key={color} className="color-option" style={{ backgroundColor: color }} onClick={() => { onColorSelect(color); onClose(); }} />
            ))}
        </div>
    );
};

function TaskTypeHolder({ taskTypes, taskCounts, selectedTaskType, onTaskTypeClick, onTaskTypesReorder }) {
    const [isAddingTaskType, setIsAddingTaskType] = useState(false);
    const [newTaskType, setNewTaskType] = useState('');

    const handleAddNewTaskType = () => {
        if (newTaskType.trim()) {
            onTaskTypesReorder([...taskTypes, newTaskType.trim()]);
            setNewTaskType('');
            setIsAddingTaskType(false);
        }
    };

    return (
        <div className='taskTypeHolder'>
            <SortableContext items={taskTypes} strategy={verticalListSortingStrategy}>
                {taskTypes.map((type) => (
                    <TaskType 
                        key={type} 
                        id={type} 
                        type={type} 
                        count={taskCounts[type]} 
                        isSelected={selectedTaskType === type}
                        onClick={() => onTaskTypeClick(type)}
                    />
                ))}
            </SortableContext>
            <button className='addTaskType' onClick={() => setIsAddingTaskType(true)}>
                <RiFolderAddLine />
            </button>
            {isAddingTaskType && (
                <div className='addTaskTypeWindow'>
                    <input
                        type='text'
                        placeholder='New task type'
                        value={newTaskType}
                        onChange={(e) => setNewTaskType(e.target.value)}
                    />
                    <button onClick={handleAddNewTaskType}>Add</button>
                    <button onClick={() => setIsAddingTaskType(false)}>Cancel</button>
                </div>
            )}
        </div>
    );
}

function TaskType({ id, type, count, isSelected, onClick }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            className={`taskType ${isSelected ? 'selected' : ''}`}
            onClick={onClick}
        >
            <span>{type}</span>
            {count > 0 && <span className="count">{count}</span>}
        </div>
    );
}
  

function TaskHolder({tasks, removeTask, addTask, deleteTask, taskType, customColors, setCustomColors, folders, addTaskToFolder }) {

    // Add task window
    const {userToken} = useContext(UserContext);
    const [addTaskWindow, setAddTaskWindow] = useState(false);
    const [taskTitle, setTaskTitle] = useState('');
    const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState('');


    // Give style based on task type
    const getCustomColor = (type) => customColors[type] || '#132E32'; // Default blue color

    const holderStyle = (type) => ({
        backgroundColor: `${getCustomColor(type)}28`
    });

    const taskStyle = (type) => ({
        backgroundColor: getCustomColor(type)
    });

    const tagStyle = (type) => ({
        backgroundColor: getCustomColor(type)
    });


    // Add task button
    const addTaskFunction = () => {
        let title = '';
        if (phoneError) {
            toast.error('Invalid phone number');
            return;
        }
        if (taskTitle === '') {
            toast.error('Task title cannot be empty');
            return;
        }
        if (phoneNumber) {
            title = 'Phone: ' + phoneNumber.replace(/^\+/, '').replace(/\s+/g, '') + '\n\n' + 'Task: ' + taskTitle;
        } else {
            title = 'Task: ' + taskTitle;
        }

        setAddTaskWindow(false); 
        addTask(title, taskType); 
        setTaskTitle('');
    }

    const handleColorSelect = async(color) => {
        
        try {
            const newCustomColors = {...customColors, [taskType]: color};
            setCustomColors(newCustomColors);
            console.log(userToken, color, taskType)
            const response = await API.editTaskType({'token': userToken, 'task_color': color, 'task_type': taskType})
            console.log(response);
        } catch (error) {
            console.error(error);
        }
    };
    
    const handlePhoneChange = (e) => {
        let input = e.target.value;
        if (input.length === 1 && input !== '+') {
            input = '+' + input;
        }
        setPhoneNumber(input);

        try {
            if (input) {
                const parsedNumber = parsePhoneNumber(input);
                if (isValidPhoneNumber(input)) {
                    setPhoneNumber(parsedNumber.formatInternational());
                    setPhoneError('');
                } else {
                    setPhoneError('Invalid phone number');
                }
            } else {
                setPhoneError('');
            }
        } catch (error) {
            setPhoneError('Invalid phone number');
        }
    };

    

    return (
        <div className='taskHolder'>
            <div className='taskHeader'>
            </div>
            <ColorPicker isOpen={isColorPickerOpen} onClose={() => setIsColorPickerOpen(false)} onColorSelect={handleColorSelect}/>

            <div className='taskItem'>
                <SortableContext items={tasks} strategy={verticalListSortingStrategy}>
                    {tasks.map((task) => (
                        <Task id={task.id} title={task.task_title} type={task.task_type} key={task.id} removeTask={removeTask}/>
                    ))}
                </SortableContext>
            </div>

            <button className='addTask' style={{ display: addTaskWindow ? 'none': 'block'}} onClick={() => setAddTaskWindow(true)}> 
                + Add New 
            </button>

            <div className='addTaskWindow' style={{display: addTaskWindow ? 'flex' : 'none'}}>
                <div className='addTaskHeader'>
                    <IoIosArrowBack className='taskHeaderBackBtn' style={{fontSize:'1.25rem'}} onClick={() => setAddTaskWindow(false)}/> 
                    <p><b>Add new task</b></p>
                </div>
                <div class="input-container">
                    <input type="tel" id="phoneInput" placeholder="Enter phone number" value={phoneNumber} onChange={handlePhoneChange}/>
                    <label htmlFor="phoneInput">Phone number (optional)</label>
                    {phoneError && <div className="error-message">{phoneError}</div>}
                </div>
                <div className="input-container">
                    <textarea type='text' id='task-title' placeholder='Task title' value={taskTitle} onChange={(evt) => setTaskTitle(evt.target.value)}/>
                    <label htmlFor='task-title'>Task title</label>
                </div>
                <button style={taskStyle(taskType)} onClick={addTaskFunction}>Add task</button>
            </div>
        </div>
    );
}

function Task({id, title, type, removeTask} ) {

    // Telling dnd that Task is a sortable and draggable object
    const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id});
    const [phoneNumber, setPhoneNumber] = useState('');
    const navigate = useNavigate();

    // Determine behaviour of Task when being dragged
    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
    }

    useEffect(() => {
        const prefixes = ['Phone:', 'Name:', 'Task:'];
        const regex = new RegExp(`(${prefixes.join('|')})`, 'g');
        const parts = title.split(regex).filter(Boolean);

        for (let i = 0; i < parts.length; i++) {
            const part = parts[i];
            const prefix = prefixes.find(p => p === part);
            if (prefix && prefix === 'Phone:' && i + 1 < parts.length) {
                setPhoneNumber(parts[i + 1].trim());
                break; // Exit the loop once the phone number is found
            }
        }
    }, [title]);


    const renderTitle = (title) => {
        const prefixes = ['Phone:', 'Name:', 'Task:'];
        const regex = new RegExp(`(${prefixes.join('|')})`, 'g');
        const parts = title.split(regex).filter(Boolean);
    
        const rows = [];
        for (let i = 0; i < parts.length; i++) {
            const part = parts[i];
            const prefix = prefixes.find(p => p === part);
            if (prefix) {
                rows.push(
                    <tr key={i} className="task-title-row">
                        <td className={`${prefix.toLowerCase().replace(':', '')}-tag`}>
                            {prefix.slice(0, -1)}
                        </td>
                        <td>{i + 1 < parts.length && parts[i + 1]}</td>
                    </tr>
                );
                i++; // Skip the next part as it has been processed
            }
        }
    
        return (
            <table className="task-title-table">
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    };

    const navigateInbox = (phoneNumber) => {
        navigate('/main/inbox/'+phoneNumber+'/');
    }

    return (
        <div ref={setNodeRef} style={style} className='task'>
            <div {...attributes} {...listeners} className='taskDragHandle'>
                {renderTitle(title)}
            </div>
            <div className='taskButtonContainer'>
                <button className='taskSendButton' onClick={() => navigateInbox(phoneNumber)} style={{'display': phoneNumber ? 'block': 'none'}}><IoSend /></button>
                <button className='taskDeleteButton' onClick={() => removeTask(id)}><IoCheckmarkDoneCircle /></button>
            </div>
        </div>
    )
}

export { TaskHolder, Task };