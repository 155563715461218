import React, { useState, useEffect, useRef, useContext } from 'react';
import { Handle, Position } from 'reactflow';
import Select from 'react-select';
import { toast } from "react-toastify";
import { UserContext } from '../user-context.js';

import { FaBoltLightning, FaCircleStop } from 'react-icons/fa6';
import { IoIosArrowBack } from "react-icons/io";
import { TiFlowMerge } from "react-icons/ti";
import { TbTemplate } from "react-icons/tb";
import { BiMessage } from "react-icons/bi";
import { LuListTodo } from "react-icons/lu";
import { AiFillHome } from "react-icons/ai";
import { MdOutlinePermMedia, MdCancel } from "react-icons/md";


function StartNode({ data, isConnectable }){
    return (
        <div className='startNode'>
            <div className='startNode-body'>
                <FaBoltLightning/> <b>Start</b>
            </div>
            <Handle type='source' position={Position.Bottom} id='startNode' isConnectable={isConnectable}/>
        </div>
    )
}

function CheckInNode({ data, isConnectable }){
    return (
        <div className='startNode'>
            <div className='startNode-body'>
                <FaBoltLightning/> <b>Check-in</b>
            </div>
            <Handle type='target' position={Position.Top} id='checkInTarget' isConnectable={isConnectable}/>
            <Handle type='source' position={Position.Bottom} id='checkInSource' isConnectable={isConnectable}/>
        </div>
    )
}

function CheckOutNode({ data, isConnectable }){
    return (
        <div className='startNode'>
            <div className='startNode-body'>
                <FaBoltLightning/> <b>Check-out</b>
            </div>

            <Handle type='target' position={Position.Top} id='checkOutTarget' isConnectable={isConnectable}/>
            <Handle type='source' position={Position.Bottom} id='checkOutSource' isConnectable={isConnectable}/>
        </div>
    )
}

function ConditionNode({id, data, isConnectable }){

    return (
        <div className='conditionNode node'>
            <div className='conditionNode-head'>
                <TiFlowMerge size={'1rem'}/><b>Condition</b>
            </div>

            <div className='conditionNode-body'>
                <input id="conditionText" value={data.text} name="conditionText" onChange={(evt)=>data.onChange(id, evt.target.value)} className="nodrag" />
            </div>
            <Handle type='target' position={Position.Top} id='conditionTarget' isConnectable={isConnectable}/>
            <Handle type='source' position={Position.Bottom} id='conditionMatch' isConnectable={isConnectable}/>
        </div>
    )
}


function MsgNode({ id, data, isConnectable }){

    const textAreaHeight = 'auto';
    const textAreaRef = useRef(null);

    useEffect(() => {
        if (textAreaRef.current) {
            const target = textAreaRef.current;
            target.style.height = 'auto';
            target.style.height = `${target.scrollHeight}px`;
        }
    }, []);

    const handleInput = (e) => {
        let target = e.target;
        target.style.height = 'auto';
        target.style.height = (target.scrollHeight) + 'px';
    };

    return (
        <div className='msgNode node'>
            <div className='msgNode-head'>
                <BiMessage size={'1.25rem'}/><b>MESSAGE</b>
            </div>
            <div className='msgNode-body'>
                <textarea id="msg" name="msg" ref={textAreaRef} value={data.text} onChange={(evt) => data.onChange(id, evt.target.value)} className="nodrag"  style={{ height: textAreaHeight }} onInput={handleInput}/>
            </div>
            <Handle type='target' position={Position.Top} id='msgTarget' isConnectable={isConnectable}/>
            <Handle type='source' position={Position.Bottom} id='msgSource' isConnectable={isConnectable}/>
        </div>
    )    
}

function TemplateNode({id, data, isConnectable }){
    
    const { wabaId, userId, SystemUserToken } = useContext(UserContext);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    useEffect(() => {
        const getWhatsAppTemplate = async() => {

            // Set default template and set selected template to value obtained from database
            const response = await fetch('https://graph.facebook.com/v20.0/'+ wabaId +'/message_templates?limit=250&fields=name&status=APPROVED', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + SystemUserToken,
                }
            })
            const resp = await response.json();
            const templateList = resp.data?.map((template) => {
                return {
                    'value': template.name,
                    'label': template.name,
                }
            })
            setTemplates(templateList);
            setSelectedTemplate(templateList?.find((item) => item.value === data.text.match(/^[^{]*/)[0]));
        }

        getWhatsAppTemplate();
    }, [userId]);


    const handleTemplateChange = (evt) => {
        setSelectedTemplate(evt);
        data.onChange(id, evt.value);
    }


    return (
        <div className='msgNode node'>
            <div className='msgNode-head'>
                <TbTemplate /><b>WhatsApp Template</b>
            </div>

            <div className='todolistNode-body'>
                <Select options={templates} value={selectedTemplate} onChange={(evt) => handleTemplateChange(evt)} className="nodrag"/>
            </div>

            <Handle type='target' position={Position.Top} id='templateTarget' isConnectable={isConnectable}/>
            <Handle type='source' position={Position.Bottom} id='templateMatch' isConnectable={isConnectable}/>
        </div>
    )
}

function MediaNode({id, data, isConnectable }){

    // Track uploaded file
    const [file, setFile] = useState(null);

    // Fetch file from server
    useEffect(() => {
        if (!data.node_file) {
            return;
        }
        fetch(data.node_file)
            .then(response => response.blob())
            .then(blob => {setFile(new File([blob], data.node_file, {type: blob.type}));})
    }, []);

    // Display uploaded file
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFile(file);
    };

    // Remove uploaded file
    const handleRemoveFile = () => {
        setFile(null);
        data.fileOnChange(id, null);
    };

    let fileUrl;
    if (file) {
        fileUrl = URL.createObjectURL(file);
    }

    return (
        <div className='mediaNode node'>
            <div className='mediaNode-head'>
                <MdOutlinePermMedia size={'1rem'}/><b>Media (Image, PDF, Video)</b>
            </div>

            <div className='mediaNode-body'>
                <label htmlFor={`mediaFile-input-${id}`} className="customFileUpload">
                    Choose File
                </label>
                <input type="file" id={`mediaFile-input-${id}`} key={id} onChange={(e) => {handleFileChange(e); data.fileOnChange(id, e.target.files[0]);}} className="nodrag hidden" />
                {file && file.type.startsWith('image/') && <img src={fileUrl} alt="Preview" className="mediaFile-style" width="85%"/>}
                {file && file.type === 'application/pdf' && <object data={fileUrl} type="application/pdf" className="borderRadius" width="85%"></object>}
                {file && file.type.startsWith('video/') && <video controls src={fileUrl} className="borderRadius" width="85%"></video>}
                {file && <button onClick={handleRemoveFile} className="removeFileButton">Remove File</button>}
            </div>


            <Handle type='target' position={Position.Top} id='mediaTarget' isConnectable={isConnectable}/>
            <Handle type='source' position={Position.Bottom} id='mediaMatch' isConnectable={isConnectable}/>
        </div>
    )
}


function ToDoListNode({id, data, isConnectable }){

    const todolistType = [{value: "Extensions", label: "Extensions"}, {value: "Inquiries", label: "Inquiries"}, {value: "NoWhatsApp", label: "NoWhatsApp"}];
    const [selectedList, setSelectedList] = useState(todolistType.find((item) => item.value === data.text));

    return (
        <div className='todolistNode node'>
            <div className='todolistNode-head'>
                <LuListTodo/><b>To do list</b>
            </div>

            <div className='todolistNode-body'>
                <Select options={todolistType} value={selectedList} onChange={(evt) => {setSelectedList(evt); data.onChange(id, evt.value)}} className="nodrag"/>
            </div>
            <Handle type='target' position={Position.Top} id='todolistTarget' isConnectable={isConnectable}/>
            <Handle type='source' position={Position.Bottom} id='todolistMatch' isConnectable={isConnectable}/>
        </div>
    )
}


function UnitNode({id, data, isConnectable }){

    const [isAddNewUnit, setIsAddNewUnit] = useState(false);
    const [unit, setUnit] = useState('');
    const [unitList, setUnitList] = useState([]);
    
    useEffect(() => {
        setUnitList(JSON.parse(data.text || '[]'));
        data.unitOnChange('add', JSON.parse(data.text || '[]'))
    }, []);

    const handleAddUnit = () => {
        if (unit === '') {
            toast.error('Unit number cannot be empty');
            return;
        }

        if(data.unitOnChange('add', unit)){
            setUnitList([...unitList, unit]);
            data.onChange(id, JSON.stringify([...unitList, unit]));
        } else {
            toast.error('Unit number already exists');
        }

        setUnit('');
        setIsAddNewUnit(false);
    }

    const handleDeleteUnit = (unit) => {
        const newUnitList = unitList.filter((item) => item !== unit);
        setUnitList(newUnitList);
        data.unitOnChange('remove', unit);
        data.onChange(id, JSON.stringify(newUnitList));
    }

    return (
        <div className='unitNode node'>
            <div className='unitNode-head'>
                <AiFillHome/><b>Unit No</b>
            </div>

            <div className='unitNode-body'>
                <div className='unit-grid'>
                    {unitList.map((unit, index) => {
                        return (
                            <div className='unit-card' key={index}>
                                {unit}
                                <MdCancel className='cancel-active' onClick={() => handleDeleteUnit(unit)}/>
                            </div>
                        )

                    })}
                </div>

                <div className={`add-unit-input ${isAddNewUnit ? 'unit-active' : ''}`}>
                    <div className='add-unit-input-header'>
                        <IoIosArrowBack onClick={() => setIsAddNewUnit(false)} style={{fontSize: '1rem'}}/>
                        <h3>Add new unit</h3>
                    </div>
                    <div className='input-container'>
                        <input id="unitNode" placeholder='Enter unit number' value={unit} name="unitNode" onChange={(evt)=>setUnit(evt.target.value)} className="nodrag" />
                        <label htmlFor="unitNode">Enter unit number</label>
                    </div>
                    <button className='add-unit-button' onClick={() => handleAddUnit()}>
                        Add new unit
                    </button>
                </div>

                <div className='add-unit-div'>
                    <button className='add-unit-card' onClick={() => setIsAddNewUnit(true)} style={{'display': isAddNewUnit ? 'none': 'block'}}>
                        Add new units
                    </button>
                </div>
            </div>
          
            <Handle type='target' position={Position.Top} id='unitTarget' isConnectable={isConnectable}/>
            <Handle type='source' position={Position.Bottom} id='unitMatch' isConnectable={isConnectable}/>
        </div>
    )
}

function EndNode({ data, isConnectable }){
    return (
        <div className='endNode node'>
            <FaCircleStop/> <b>END</b>
            <Handle type='target' position={Position.Top} id='endNode' isConnectable={isConnectable}/>
        </div>
    )
}


export {StartNode, CheckInNode, CheckOutNode, ConditionNode, MsgNode, TemplateNode, MediaNode, ToDoListNode, UnitNode, EndNode};